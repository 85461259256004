import { FC, useState } from "react"
import { useI18n } from "next-localization"
import { ProductGroupsList } from "@app/features/products/components/product-groups-list"

import styles from "./products-section.module.scss"

type TProductsSectionProps = {
  title?: string
  description?: string
  onlyAvailable?: boolean
  position?: "modal" | "footer"
}

const ProductsSection: FC<TProductsSectionProps> = ({
  title,
  description,
  onlyAvailable = false,
  position,
}) => {
  const { t } = useI18n()
  const [productDiscount, setProductDiscount] = useState<number>()

  const handleDiscountSuccess = (discount: number) => {
    if (!title) {
      setProductDiscount(discount)
    }
  }

  const sectionTitle = !!productDiscount
    ? t("productsModal.base.discountTitle")
    : title || t("productsModal.base.title")
  const sectionDescription = !!productDiscount
    ? t("productsModal.base.discountDescription", { discount: productDiscount })
    : description || t("productsModal.base.description")

  return (
    <div className={styles["root"]}>
      <h2 className={styles["title"]}>{sectionTitle}</h2>
      <p className={styles["description"]}>{sectionDescription}</p>
      <div className={styles["content"]}>
        <ProductGroupsList
          onDiscountSuccess={handleDiscountSuccess}
          onlyAvailable={onlyAvailable}
          position={position}
        />
      </div>
    </div>
  )
}

export { ProductsSection }
